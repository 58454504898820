	/** 获取url中的参数*/
	function getUrlParam(name) {
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
		var r = window.location.search.substr(1).match(reg); //匹配目标参数
		if (r != null) return decodeURI(r[2]); return null; //返回参数值
	}
	
	//显示初始错误消息
	(function showErrorMsg(){
		var errorMsg = getUrlParam("errorMsg");
		if(errorMsg){
			$(".login-error").html("<span>!</span><label>"+errorMsg+"</label>"); 
		}
	})();
	
	//获取url中的参数赋值给页面元素
	$("#rurl").val(getUrlParam("url"));//设置登录后跳转地址
	
	//键盘事件
	$(document).keydown(function(event){ 
		if(event.keyCode == 13){ 
			$("#sign-in").click(); 
		}
	});
	
	function jPlaceHolder(obj){
		var self = obj;
		var id = '#' + self.attr('id') + '-span';
		var holder = $(id);
	    if(!self.val()){
	        holder.show();
	    } else {
	    	holder.hide();
	    }
	}

	$(":input[placeholder]").bind("input propertychange", function() {
		jPlaceHolder($(this));
	});
	
	function checkVcodeImage(){
		var login = $.trim($("#loginId").val());
		if(!login){
			hideVcodeImage();
			return;
		}
		
		var datas = {
			"login":$.trim($("#loginId").val())
		};
		
		dc_ajax({
			type: "POST",
			url:"/need-vcode",
	        data:datas,
	        dataType: "json",
	        async: false,
			error: function(request) {
				console.log(request);
			},
			success: function(ret) {
				if(ret.data == 1){
					showVcodeImage($.trim($("#loginId").val()));
					return false
				}
				
				if(ret.data == 0){
					hideVcodeImage();
					return false
				}
				return true;
			}
		});
	}
	
	function showVcodeImage(login){
		$(".login-input").addClass("login-input-with-code");
		$("#vcodeId").val("");
		$(".vcode").attr("src","/vcode/get?login="+login+"&r="+Math.random());
		$(".login-vcode").show();
	}
	
	function hideVcodeImage(){
		$(".login-vcode").hide();
		$(".login-input").removeClass("login-input-with-code");
	}
	
	$("body").on("change","#loginId",function(){
		checkVcodeImage();
	});
	
	$("body").on("click",".vcode", function(){
		$(this).attr("src","/vcode/get?login="+$.trim($("#loginId").val())+"&r="+Math.random());
	});
	
	//点击登录按钮
	$("#sign-in").click(function(){
		var passVal = $.trim($("input[type='password']").val());
		var val = $.trim($("input[type='text']").val());
		if(!val){
			$(".login-error").html("<span>!</span><label>请输入用户名</label>");
			return false;
		}
		
		if(!/^[0-9A-Za-z_]{6,15}$/.test(val)){
			$(".login-error").html("<span>!</span><label>请输入6到15位字母,数字或下划线的用户名</label>");
			return false;
		}
		
		if(!passVal){
			$(".login-error").html("<span>!</span><label>请输入密码</label>");
			return false;
		}
		
		if(!/^[0-9a-zA-Z]{6,20}$/.test(passVal)){
			$(".login-error").html("<span>!</span><label>请输入6到20位字母或数字的密码</label>");
			return false;
		}
		
		var vcode = $.trim($("#vcodeId").val());
		if(!$(".login-vcode").is(":hidden")){
			if(!vcode){
				$(".login-error").html("<span>!</span><label>请输入验证码</label>");
				return false;
			}
			
			if(!/^[0-9a-zA-Z]{4,4}$/.test(vcode)){
				$(".login-error").html("<span>!</span><label>请输入4位字母或数字的验证码</label>");
				return false;
			}
		}
		$("#loginForm").submit();
	});
	
	$(".logo img").click(function(){
		window.location.reload(); 
	});